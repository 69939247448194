<template>
  <div>
    <BittsInput
      v-model="searchValue"
      class="my-8"
      name="search-partners"
      placeholder="Search partners"
      size="small"
    >
      <template #prefix>
        <FontAwesomeIcon
          :icon="['fa', 'magnifying-glass']"
          :style="{
            height: '14px',
            width: '14px',
            color: 'currentColor',
          }"
          class="text-info-accent pr-6"
        />
      </template>
    </BittsInput>
    <BittsBaseTree
      :is-disabled
      :node-data="filteredNodeData"
      :expand-all="expandAll"
      @update="onUpdate"
    >
      <template #node-suffix="{ node }: { node: TreeNodeType }">
        <slot name="node-suffix" :node="node"></slot>
      </template>
    </BittsBaseTree>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

import BittsInput from '../BittsInput/BittsInput.vue';

import BittsBaseTree, { TreeNodeType } from './BittsBaseTree.vue';

const props = withDefaults(
  defineProps<{
    isDisabled?: boolean;
    nodeData: TreeNodeType[];
    expandAll?: boolean;
  }>(),
  { expandAll: false, isDisabled: false },
);

const emit = defineEmits<{
  update: [value: TreeNodeType[], updatedNodeId: number | string];
}>();

defineSlots<{
  'node-suffix': { node: TreeNodeType };
}>();

const searchValue = ref('');

const filteredNodeData = computed(() => {
  if (!searchValue.value) {
    return props.nodeData;
  }
  const searchLowerCaseNoSpace = searchValue.value
    .replace(/\s+/g, '')
    .toLowerCase();

  return props.nodeData.filter((node) =>
    node.label
      .replace(/\s+/g, '')
      .toLowerCase()
      .includes(searchLowerCaseNoSpace),
  );
});

const mergeUpdatedNodes = (
  original: TreeNodeType[],
  updated: TreeNodeType[],
): TreeNodeType[] => {
  return original.map((originalNode) => {
    const updatedNode = updated.find((node) => node.id === originalNode.id);

    return {
      ...originalNode,
      ...(updatedNode || {}),
      children: originalNode.children
        ? mergeUpdatedNodes(originalNode.children, updatedNode?.children || [])
        : [],
    };
  });
};

const onUpdate = (
  updatedVisibleNodes: TreeNodeType[],
  updatedNodeId: number | string,
) => {
  const updatedTree = mergeUpdatedNodes(props.nodeData, updatedVisibleNodes);
  emit('update', updatedTree, updatedNodeId);
};
</script>
